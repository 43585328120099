import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from "gatsby"
import Link from 'gatsby-link'
import device from '../device'

const SubNav = styled.ul`
  display: none; //Hidden by default
  margin: 10px 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 15px 1px var(--blue-shadow-dark);
  overflow: hidden;
  background: var(--white);
  list-style: none;
  text-align: left;
  position: absolute;
  width: 190px;
  left: -10px;

  li, li:hover {
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    display: block;
    border-bottom: 2px solid var(--blue-200);

    &:last-child {
      border-bottom: 0;
    }

    a {
      color: var(--blue-800);
      height: 100%;
      width: 100%;
      display: block;
      padding: 20px;
    }
    
    a:hover {
      color: var(--blue-800);
      background-color: var(--blue-200);
    }
  }
`

const NavItem = styled.li`
  margin-left: 30px;
  border-radius: 4px;
  min-width: 90px;
  text-align: center;
  font-weight: 500;
  position: relative;
  margin-top: 10px;

  .--active {
    border-bottom: 2px solid var(--white);
  }

  &:hover {
    cursor: pointer;

    a {
      border-bottom: 2px solid var(--red);
    }

    @media ${device.tablet} {
      ${SubNav} {
        display: block;
  
        a, a:hover {
          border: 0;
        }
      }
    }    
  }

  .--active {
    border-bottom: 2px solid var(--white);
  }

  :last-of-type {
    border: 1px solid ${({ darkMode }) => darkMode ? '#FFF' : 'var(--blue-900)'};
    padding: 0;
    margin-top: 0;

    .--active {
      border-bottom: 0;
    }

    a {
      padding: 10px;
      display: inline-block;
      height: 100%;
      width: 100%;
    }

    a:hover {
      color: ${({ darkMode }) => darkMode ? 'var(--blue-900)' : '#FFF'};
      background: ${({ darkMode }) => darkMode ? '#FFF' : 'var(--blue-900)'};
      border-bottom: 0;
    }
  }
`

const Nav = ({ className, darkMode }) => (
  <StaticQuery
    query={graphql`
      query NavQuery{
        prismicNav {
          id
          data {
            display_name {
              text
            }
            nav {
              primary{
                label {
                  text
                }
                link {
                  url
                }
      
              }
              items {
                sub_nav_link_label {
                  text
                }
                sub_nav_link {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { nav } = data.prismicNav.data
      console.log(data)
      return (
      <ul className={className}>
        {nav && nav.map(({ primary, items }) => (
          <NavItem key={primary.label.text} darkMode>
            <Link to={primary.link.url} activeClassName="--active">
              {primary.label.text}
            </Link>
            {items && items.length && items[0].sub_nav_link !== null && (
              <SubNav darkMode>
                {items.map(({ sub_nav_link_label, sub_nav_link }) => sub_nav_link !== null && (
                  <li key={sub_nav_link_label.text}>
                    <Link to={sub_nav_link.url}>
                      {sub_nav_link_label.text}
                    </Link>
                  </li>
                ))}
              </SubNav>
            )}
          </NavItem>
        ))}
      </ul>
    )}}
  />
)

export default styled(Nav)`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    color: ${({ darkMode }) => darkMode ? '#FFF' : 'var(--blue-900)'};
    text-decoration: none;
  }
  
  a:hover {
    color: ${({ darkMode }) => darkMode ? '#FFF' : 'var(--blue-900)'};
    border-bottom: solid 2px var(--red);
  }
`