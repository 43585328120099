import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import SiteFooter from './SiteFooter'
// import favicon from '../favicon.png';

require('../global/styles.js')

const LayoutContent = styled.div`
  flex: 1 1 100%;
`

const description = 'Charthouse Data Management';

const Layout = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: description },
            // { name: 'image', content: favicon },
            { property: 'og:title', content: data.site.siteMetadata.title },
            { property: 'og:description', content: description },
            // { property: 'og:image', content: favicon },
            { property: 'og:type', content: 'website'},
            { property: 'fb:app_id', content: '' },
            { name: 'twitter:title', content: data.site.siteMetadata.title },
            { name: 'twitter:description', content: description },
            // { name: 'twitter:image', content: favicon },
            { name: 'twitter:creator', content: '@charthouse' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className={className}>
          <LayoutContent>
            {children}
          </LayoutContent>
          <SiteFooter />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`