import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import Nav from './Nav'
import device from '../device'

import LogoDark from '../images/CH_wordmark_black.svg'
import LogoLight from '../images/CH_wordmark.svg'

const Container = styled.div`
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderLogo = styled.img`
  height: 14px;

  @media ${device.mobileM} {
    height: 16px;
  }

  @media ${device.tablet} {
    height: 20px;
  }
`

const MenuButton = styled.button`
  background: none;
  border: 1px solid ${({ darkMode }) => darkMode ? 'var(--blue-200)' : 'var(--blue-900)'};
  color: ${({ darkMode }) => darkMode ? 'var(--blue-200)' : 'var(--blue-900)'};
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  font-weight: 500;
  display: block;
  
  svg {
    fill: ${({ darkMode }) => darkMode ? 'white' : 'var(--blue-900)'};
  }

  @media ${device.tablet} {
    display: none;
  }
`

const DesktopMenu = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--blue-800);
  padding: 40px 20px;
  z-index: 999;
  overflow: scroll;

  ${Nav} {
    display: block;
    margin: 0;
    padding: 0 0 20px;

    a {
      color: var(--blue-200);
    }

    > li {
      margin: 0 0 10px 0;
      font-size: 30px;
      padding: 20px;
      text-align: left;


      a:hover {
        color: var(--white);
      }

      // Override contact button styling
      :last-of-type {
        border: 0;
        margin: 0 0 10px 0;
        padding: 20px;

        a { 
          padding: 0;
          width: auto;
          min-width: 0;
          border-bottom: solid 2px transparent;
        }

        a:hover {
          background: 0;
          border-bottom: solid 2px var(--red);
        }
      }
    }
  }

  button {
    font-size: 24px;
    background: none;
    border: 0;
    padding: 20px;
    cursor: pointer;
    color: var(--blue-200);
  }

  @media ${device.tablet} {
    display: none;
  }
`;

class SiteHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false }
  }
  render() {
    const { className, darkMode } = this.props
    const { menuOpen } = this.state;
    return (
      <div className={className}>
        <Container>
          {menuOpen && (
            <MobileMenu>
              <Nav />
              <button onClick={() => this.setState({ menuOpen: false })}>
                &times; Close
              </button>
            </MobileMenu>
          )}
          <Link to="/"><HeaderLogo src={darkMode ? LogoLight : LogoDark} alt="Charthouse" /></Link>
          <DesktopMenu>
            <Nav darkMode={darkMode} />
          </DesktopMenu>
          <MenuButton 
            darkMode={darkMode} 
            onClick={() => this.setState({ menuOpen: !menuOpen })}
          >
            Menu
          </MenuButton>
        </Container>
      </div>
    );
  }
}

export default styled(SiteHeader)`
  padding: 30px 20px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`