import React from 'react'
// import Link from 'gatsby-link'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Container from './Container'
import device from '../device.js'

const footerLinksQuery = graphql`
  query FooterQuery{
    prismicFooter {
      data {
        left_column_heading {
          text
        }
        left_column_links {
          link {
            url
          }
          label {
            text
          }
        }
        center_column_heading {
          text
        }
        center_column_links {
          link {
            url
          }
          label {
            text
          }
        }
        right_column_heading {
          text
        }
        right_column_links {
          link {
            url
          }
          label {
            text
          }
        }
        copyright_details {
          text
        }
        legal_copy {
          text
        }
      }
    }
  }
`

const LinkList = ({ className, links }) => (
  <ul className={className}>
    {links.map(({ link, label }) => link && link.url !== '/undefined' && (
      <li key={label.text}>
        <a href={link && link.url}>
          {label.text}
        </a>
      </li>
    ))}
  </ul>
)

const FooterColumns = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

const Column = styled.div`
  padding: 30px 10px;
  flex: 1 1 300px;

  h4 {
    font-size: 20px;
    margin: 0 0 20px 0;
    font-weight: 600;
    color: var(--blue-400)
  }
`

const LegalCopy = styled.div`
  margin-top: 80px;

  h5, h5 > a {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--blue-300);
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    line-height: 1.5;

  }

  small > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    color: var(--blue-300);
    letter-spacing: 0.5px;
  }
`

const StyledLinkList = styled(LinkList)`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 20px 0;
  }

  li > a {
    text-decoration: none;
    color: var(--blue-300)
  }

  li > a:hover {
    text-decoration: underline;
    color: var(--blue-200)
  }
`

const SiteFooter = ({ className }) => (
  <StaticQuery
    query={footerLinksQuery}
    render={data => {
      const footer = data.prismicFooter.data;
      return (
        <div className={className}>
          <Container>
            <FooterColumns>
              <Column>
                <h4>{footer.left_column_heading.text}</h4>
                <StyledLinkList links={footer.left_column_links} />
              </Column>
              <Column>
                <h4>{footer.center_column_heading.text}</h4>
                <StyledLinkList links={footer.center_column_links} />
              </Column>
              <Column>
                <h4>{footer.right_column_heading.text}</h4>
                <StyledLinkList links={footer.right_column_links} />
              </Column>
            </FooterColumns>
            <LegalCopy>
              <h5>&copy; {footer.copyright_details.text}</h5>
              <small>
                <p>
                {footer.legal_copy.text}
                </p>
              </small>
            </LegalCopy>
          </Container>
        </div>
      )
    }}
  />
)

export default styled(SiteFooter)`
  background-color: var(--blue-900);
  padding: 60px 20px 40px;
  flex-shrink: 0;
`