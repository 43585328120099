import { injectGlobal } from 'styled-components'
import 'sanitize.css'
import device from '../device.js'

export default injectGlobal`
  :root {
    --blue-900: rgba(6, 22, 41, 1);
    --blue-800: rgba(22, 44, 69, 1);
    --blue-700: rgba(26, 64, 107, 1);
    --blue-600: rgba(22, 81, 148, 1);
    --blue-500: rgba(62, 112, 168, 1);
    --blue-400: rgba(109, 152, 201, 1);
    --blue-300: rgba(174, 201, 232, 1);
    --blue-200: rgba(217, 235, 255, 1);
    --blue-100: rgba(247, 251, 255, 1);
    --blue-shadow-dark: rgba(6, 22, 41, 0.3);
    --blue-shadow: rgba(62, 112, 168, 0.5);
    --red: rgba(230, 47, 40, 1);
    --white: rgba(255, 255, 255, 1);
  }

  * {
    color: var(--blue-900);
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
  
    :disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  html, body, #___gatsby {
    height: 100%;
    margin: 0;
  }

  ::selection {
    background-color: var(--blue-500);
  }

  h1 {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    color: var(--blue-900);
    line-height: 1.25;
    font-weight: 700;
  }

  p {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;

    @media ${device.mobileM} {
      font-size: 18px;
    }

    @media ${device.tablet} {
      font-size: 20px;
      line-height: 2;
    }
  }

  a {
    text-decoration: underline;
    color: var(--blue-500);
  }

  a:hover {
    color: var(--blue-600);
  }
`;